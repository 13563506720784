<template>
    <div>
        <h1 id="page-title">{{ material ? material.title : '' }}</h1>

        <div class="material-page spacecard">

            <PresentationPreview :slides="currentSlides"
                                 class="px-3"
                                 :slide="slide[slidemode]"
                                 :homework="false"
                                 v-if="loaded"
                                 :material="material"
                                 :host="host"
                                 @slidemodechange="slidemodechange"
                                 :slidemode="slidemode"/>
            <div v-if="loaded" class="w-100">
                <PresentationPreviewNavigation @slidechanged="slidechanged"
                                               @slidemodechange="slidemodechange"
                                               @finishhomework="homeworkDone"
                                               :slide="slide[slidemode]"
                                               :homework="false"
                                               :slidemode="slidemode"
                                               :slides="currentSlides"/>
            </div>
        </div>
    </div>
</template>

<script>
    import PresentationPreview from '@/views/parts/class/preview/PresentationPreview'
    import PresentationPreviewNavigation from '@/views/parts/class/preview/PresentationPreviewNavigation'
    import { LessonsService } from '@/services/api.service'

    export default {
        components: {
            PresentationPreview,
            PresentationPreviewNavigation,
        },
        data() {
            return {
                slides: {
                    "lesson": [],
                    "homework": []
                },
                slide: {
                    "lesson": 0,
                    "homework": 0
                },
                loaded: false,
                slidemode: 'lesson',
                material: null,
                material_id: this.$route.params.id
            }
        },
        computed: {
            currentSlides() {
                return this.slides[this.slidemode]
            },
            host() {
                let user = this.$store.state.user
                if(user.primary_role_id == 3) {
                    return true
                }
                if(!this.material) {
                    return false
                }
                let course = this.material.course
                if(!course) {
                    return false
                }
                return course.type == 'custom' && (course.instructor_id == user.id || course.school_id == user.id)
            },
        },
        methods: {
            getSlides() {
                LessonsService.getSlidesForMaterial(this.material_id).then(res => {
                    this.slides = res.data.data.slides
                    this.material = res.data.data.material
                    this.loaded = true
                })
            },
            slidechanged(slide) {
                this.slide[this.slidemode] = slide
                this.$nextTick(() => {
                    window.scrollTo(0,0)
                })
            },
            slidemodechange(mode) {
                this.slidemode = mode
            },
            homeworkDone() {
                // if(!this.lesson) { return }
                // let params = {
                //     lesson_id: this.lesson.id,
                // }
                // StudentPageService.homeworkDone(params).then(() => {
                //     this.$bvModal.hide('presentationpreviewmodal')
                // })
            }
        },
        mounted() {
            // if(this.homework) {
            //     this.slidemode = 'homework'
            // }
            this.getSlides()
        },
        metaInfo() {
            return {
                title: this.material ? this.material.title : this.$t('lesson'),
            }
        },
    }
</script>

